import { ReactNode } from "react";
import { CLI } from "./CLI";

interface Props {
    children: ReactNode;
}

const CLIOption = ({children}: Props) => {
  return (
    <span>
      <button className={"cliopt"} onClick={() => CLI.InputHandler(children!.toString())}>
        {children}
      </button>
    </span>
  )
}

export default CLIOption