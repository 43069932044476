import { Children, ReactNode, useEffect } from 'react';
import { useState } from 'react';
import CLIOption from './CLIOption';

class CLI {
    // VARIABLES
    static history: ReactNode[];
    static setHistory: React.Dispatch<React.SetStateAction<React.ReactNode[]>>;

    // CONSTANTS
    static cli_options = <>
        - <CLIOption>about_me</CLIOption><br/>
        - <CLIOption>email</CLIOption><br/>
        - <CLIOption>social_links</CLIOption><br/>
        - <CLIOption>games</CLIOption><br/>
    </>
    
    static welcome = <>
        <p>Welcome to CanaanEpperson.com</p>
        <p>What would you like to see?</p>
        <br/>
        {CLI.cli_options}
    </>

    // State functions
    static Print(input: ReactNode) {
        CLI.setHistory((oldHist) => [...oldHist, input]);
    }
  
    // ACTIONS
    static about_me() {
        CLI.Print(<div className='flex-container'>
            <img className='profile-pic' src="profile.jpg" alt="Hello!"></img>
            <p>
                Hello!<br/>
                &ensp;&ensp;&ensp;&ensp;
                My name is Canaan Epperson, and I am a Software Developer with three years of experience working as a DevOps Consultant for Amazon Web Services.
                I have a passion for writing elegant and efficient code to solve the needs of my customers. 
                In my free time, I enjoy personal projects like game development, small electronics, and most recently my home lab. 
                I'm always happy to help others however I can, and to that end, I post my personal projects on GitHub.<br/>
                &ensp;&ensp;&ensp;&ensp;
                Please feel free to reach out if you would like to work together!
            </p>
        </div>);
    }
    
    static email() {
        CLI.Print(<p>
        <a href='mailto:c_eps@yahoo.com'>c_eps@yahoo.com</a>
        </p>);
    }
    
    static social_links() {
        CLI.Print(<>
            <div className='flex-container'>
                <img className='logo' src="GitHub.svg" alt="GitHub logo"></img>
                <a href='https://github.com/Disciple153'>GitHub</a><br/>
            </div>
            <div className='flex-container'>
                <img className='logo' src="LinkedIn.png" alt="LinkedIn logo"></img>
                <a href='https://www.linkedin.com/in/canaanepperson/'>LinkedIn</a><br/>
            </div>
        </>);
    }
    
    static games() {
        CLI.Print(<>
            <div className='flex-container'>
                <img className='logo' src="ship.png" alt="Cat Facts Bullet Hell logo"></img>
                <a href='http://canaanepperson.com/catfactsbullethell'>Cat Facts Bullet Hell</a><br/>
            </div>
            <div className='flex-container'>
                <img className='logo' src="bird.png" alt="My Flappy Bird logo"></img>
                <a href='http://canaanepperson.com/flappybird'>Flappy Bird</a><br/>
            </div>
        </>);
    }
    
    // Global Thermonuclear War
    
    static help() {
        CLI.Print(CLI.cli_options);
    }
    
    static invalid_input(input: string) {
        CLI.Print(<>
        <p>{input}: command not found. See '<CLIOption>help</CLIOption>'.</p>
        </>)
    
    }
    
    // INPUT HANDLER
    static InputHandler(input: string) {
        CLI.Print(<p><br/>&gt; {input}</p>)
        let input_clean = input.trim().replace('-', '').replace(' ', '_').toLowerCase();
    
        switch (input_clean) {
        case 'about_me':
            CLI.about_me();
            break;
        case 'email':
            CLI.email();
            break;
        case 'social_links':
            CLI.social_links();
            break;
        case 'games':
            CLI.games();
            break;
        case 'help':
            CLI.help();
            break;
        default:
            CLI.invalid_input(input);
            break;
        }
    }
}

const CLINode = () => {
    const [input, setInput] = useState('');

    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
    });

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        const target = e.target as HTMLFormElement
        e.preventDefault();
        target.reset();

        CLI.InputHandler(input);
        setInput('');
    }

    return (
        <div className='cli cli-primary'>
            {CLI.history}
            <p><br/></p>
            <p>
            <span className='cursor'>&gt;&ensp;</span>
            <form className='inline' onSubmit={onSubmit} id='cli_input'>
                <input type='text' autoFocus onChange={(e) => {
                    setInput(e.target.value);
                }}/>
            </form>
            </p>

        </div>
    )
}

export default CLINode
export {CLI}