import './App.css';
import { useState } from 'react';
import CLINode from './components/CLI';
import { ReactNode } from 'react';
import { CLI } from './components/CLI';

function App() {
  const [hist, setHist] = useState<ReactNode[]>([CLI.welcome]);

  CLI.setHistory = setHist;
  CLI.history = hist;

  // index
  return (
    <div className='App'>
      <CLINode/>
    </div>
  );
}

export default App;
